(function($) {

    $.fn.alterClass = function (removals, additions) {

        var self = this;

        if (removals.indexOf('*') === -1) {
            // Use native jQuery methods if there is no wildcard matching
            self.removeClass(removals);
            return !additions ? self : self.addClass(additions);
        }

        var patt = new RegExp('\\s' +
            removals.replace(/\*/g, '[A-Za-z0-9-_]+').split(' ').join('\\s|\\s') +
            '\\s', 'g');

        self.each(function (i, it) {
            var cn = ' ' + it.className + ' ';
            while (patt.test(cn)) {
                cn = cn.replace(patt, ' ');
            }
            it.className = $.trim(cn);
        });

        return !additions ? self : self.addClass(additions);
    };


    $(function() {

        let runMobileMenuToggle = function() {
            $('#menu-toggle').on('click', function() {
                $(this).toggleClass('active')
                $('.header').toggleClass('active')
            })
        }

        runMobileMenuToggle()

        let runSlider = function($scope) {
            let id = $scope.attr('id') || false
            let idSelector = '#'+ id
            let elem = $scope.find('.swiper-container').eq(0);
            if (elem.length < 1) {
                return
            }
    
            let _default = {
                loop: false,
                spaceBetween: 24,
                slidesPerView: 1,
                observer: true,
                observeParents: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                navigation: {
                    nextEl: '.button-next',
                    prevEl: '.button-prev',
                },
                lazy: true,
            }
    
            let _dataOption = elem.data('option') || {}
    
            let pagination = $scope.find('.swiper-pagination');
            let navprev = $scope.find('.button-prev');
            let navnext =  $scope.find('.button-next');
            
            console.log(idSelector + ' .button-next')

            if (navprev) {
                _default['navigation']['prevEl'] = navprev
            }
            if (navnext) {
                _default['navigation']['nextEl'] = navnext
            }
            if (pagination) {
                _default['pagination'] = {
                    el: pagination[0],
                    clickable: true,
                }
            }
    
            let option = $.extend(true, _default, _dataOption);
    
            // let slider = new Swiper(elem[0], option);

            if (elem.length > 0) {
                const slider = new Swiper(elem[0], option);
                elem.find('.swiper-wrapper').removeClass('grid').addClass('remove-grid')
                $scope.find('.button-prev').on('click', function(){
                    slider.slidePrev()
                })
                $scope.find('.button-next').on('click', function(){
                    slider.slideNext()
                })
    
                return slider;
            }

        }

        // let runCarousel = function() {
        //     let carousel = $('.carousel');
        //     if (carousel.length < 1) {
        //         return
        //     }
        //     carousel.each(function() {
        //         runSlider($(this));
        //     });
        // }
        
        // runCarousel()
        window.productCarousel = []
        window.productCatCarousel = null

        new Swiper($(".product-cats-wrap .swiper-container").eq(0)?.[0], {
            slidesPerView: 2,
            spaceBetween: 10,
            direction: getDirection(),
            navigation: {
                nextEl: ".product-cats-wrap .button-next",
                prevEl: ".product-cats-wrap .button-prev"
            },
            breakpoints: {
                768: {
                    slidesPerView: 4,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: getSlidesPerView(),
                    spaceBetween: 12,
                },
            },
            on: {
                init: function(swiper) {
                    window.productCatCarousel = swiper
                    window.productCarousel[0] = runSlider($('.products-by-cat-wrap').eq(0))
                    runMiniGame(swiper)
                    $('.product-cats .swiper-slide').on('click', function(e) {
                        let index = $(this).data('index')
                        swiper.slideTo(index)
                        swiper.slides.forEach((slide, index) => {
                            slide.classList.remove('active')
                        });
                            
                        $('.products-by-cat-wrap').removeClass('active')

                        $(this).addClass('active')
                        
                        // swiper.clickedSlide.classList.add('active')
                        console.log('click product cat', index)
                        $('.products-by-cat-wrap').eq(index).addClass('active')
                        if(!$('.products-by-cat-wrap').eq(index).hasClass('carousel')) {
                            window.productCarousel[index] = runSlider($('.products-by-cat-wrap').eq(index))
                            $('.products-by-cat-wrap').eq(index).addClass('carousel')
                        }
                    })
                },
                resize: function (swiper) {
                    swiper?.changeDirection(getDirection());
                    swiper?.update()
                },
                // click: function(swiper, event) {
                    
                // },
                slideChange: function(swiper) {
                    swiper.slides.forEach((slide, index) => {
                        slide.classList.remove('active')
                    });

                    $('.products-by-cat-wrap').removeClass('active')
                    swiper.slides[swiper.activeIndex].classList.add('active')
                    $('.products-by-cat-wrap').eq(swiper.activeIndex).addClass('active')
                    if(!$('.products-by-cat-wrap').eq(swiper.activeIndex).hasClass('carousel')) {
                        window.productCarousel[swiper.clickedIndex] = runSlider($('.products-by-cat-wrap').eq(swiper.clickedIndex))
                        $('.products-by-cat-wrap').eq(swiper.clickedIndex).addClass('carousel')
                    }
                }
            }
        });
        
        function getDirection() {
            var windowWidth = window.innerWidth;
            var direction = window.innerWidth >= 760 ? "vertical" : "horizontal";
        
            return direction;
        }
        
        function getSlidesPerView() {
            var windowHeight = window.innerHeight;
            var direction = getDirection()
            var slidesPerView = 3
        
        
            if(direction == 'vertical' && windowHeight > 900) {
                slidesPerView = 3
            }
        
            return slidesPerView
        }
        

        let runPriceProductCarousel = function () {
            window.priceProductCarousel = runSlider($('.price-filter .carousel'))
            // $('.price-filter  .carousel .button-prev').on('click', function(){
            //     priceProductCarousel.slidePrev()
            // })
            // $('.price-filter  .carousel .button-next').on('click', function(){
            //     priceProductCarousel.slideNext()
            // })
        }

        runPriceProductCarousel()
        

        let runGameProductCarousel = function () {
            window.gameProductCarousel = runSlider($('.game-filter .carousel'))
            // $('.game-filter  .carousel .button-prev').on('click', function(){
            //     gameProductCarousel.slidePrev()
            // })
            // $('.game-filter  .carousel .button-next').on('click', function(){
            //     gameProductCarousel.slideNext()
            // })
        }

        runGameProductCarousel()

        let runSwiftProductCarousel = function () {
            window.swiftProductCarousel = runSlider($('.swift-filter .carousel'))
            // $('.game-filter  .carousel .button-prev').on('click', function(){
            //     gameProductCarousel.slidePrev()
            // })
            // $('.game-filter  .carousel .button-next').on('click', function(){
            //     gameProductCarousel.slideNext()
            // })
        }

        runSwiftProductCarousel()

        function getProduct(data) {
            
            data['action'] = 'filterProduct'

            $.ajax({
                type: "POST",
                dataType: 'json',
                url: AcerSettings.ajaxUrl,
                data: data,
                success: function(response){
                    if (response.error || !response.success) {
                        return;
                    }
                    let slides = response.data.results

                    window[data['carousel']]?.removeAllSlides()
                    window[data['carousel']]?.addSlide(0, slides)

                    setTimeout(() => {
                        window[data['carousel']]?.slideTo(0)
                    }, 200)
                    console.log(window[data['carousel']], data['carousel'])
                }
           });

        }

        let runFilter = function() {
            $(document).on('click',function (e) {
                var container = $(".filter");
                if (!container.is(e.target) && container.has(e.target).length === 0)
                {
                    $('.filter-dropdown').removeClass('active')
                }
            });
            
            $('.filter .filter-selected').on('click', function(e){
                let filter = $(this).parent()
                $('.filter').not(filter).removeClass('active')
                $('.filter').not(filter).find('.filter-dropdown').removeClass('active')

                filter.addClass('active')
                filter.find('.filter-dropdown').toggleClass('active')
            })
            $('.filter-dropdown .option').on('click', function(e){
                let current = $(this),
                    value = current.data('value'),
                    label = current.find('.label').text(),
                    parent = current.parent(),
                    filter = parent.parent(),
                    text = filter.find('.filter-selected .text')

                parent.find('.option').removeClass('selected')
                current.addClass('selected')
                $('.filter-dropdown').removeClass('active')

                if(text.length) {
                    text.text(label)
                }
                
                // let filters = filter.parent().find('.filter')
                // let options = filter.parent().find('.option.selected')
                
                let filterForm = filter.parent()
                let data = {
                    tags: []
                }

                let classList = filterForm.attr('class').split(/\s+/);
                const filterCarouselName = classList[1].replace('-filter-form', '') + 'ProductCarousel'

                data['carousel'] = filterCarouselName

                if(filterForm.hasClass('price-filter-form')) {
                    data['price_filter'] = true
                }

                filterForm.find('.filter:not(.by-price) .option.selected').each(function() {
                    data['tags'].push($(this).data('value'))
                })

                if( filterForm.find('.by-price .option.selected').length ) {
                    data['price'] = filterForm.find('.filter.by-price .option.selected').data('value')
                }
                
                if(filter.hasClass('by-price')) {
                    data['price'] = value
                    // data['tags'] = []
                }


                getProduct(data)
            })
        }

        runFilter()


        function getMiniGameProduct(data, callback = () => {}) {
            
            data['action'] = 'getMiniGameProduct'

            $.ajax({
                type: "POST",
                dataType: 'json',
                url: AcerSettings.ajaxUrl,
                data: data,
                success: function(response){
                    if (response.error || !response.success) {
                        return;
                    }
                    let result = response.data

                    setTimeout(() => {
                        callback(result)
                    }, 200)
                    console.log(result?.product, result?.product_cat)
                }
           });

        }

        function runMiniGame(productCatSwiper = null, productSwiper = null) {
            
            let tags = []

            $('.btn-start-mini-game button').on('click', function() {
                console.log('Start mini game')
                $('.mini-game-start').addClass('d-none')
                $('.mini-game-start').next().removeClass('d-none')
            })

            $('.btn-restart button').on('click', function() {
                $('.acerday').addClass('d-none')
                $('.mini-game-question, .mini-game-result').addClass('d-none')
                $('.mini-game-start').removeClass('d-none')
                tags = []
            })

            $('.btn-question-back').on('click', function() {
                const question = $(this).parent().parent()
                const prevQuestion = question.prev()
                tags.pop()

                question.addClass('d-none')
                prevQuestion.removeClass('d-none')
            })

            $('button.answer').on('click', function() {
                const question = $(this).parent().parent()
                const nextQuestion = question.next()
                const tag = $(this).data('tag')
                const isLast = question.data('last')
                tags.push(tag)

                question.addClass('d-none')
                nextQuestion.removeClass('d-none')

                if( isLast && tags ) {
                    let answer = tags.join('')
                    console.log('last question')
                    const product = $(`[data-answer*=${answer}]`)
                    $('.mini-game-result .results').html(product.find('.product').clone())
                    let result = $('.mini-game-result .results .product')
                    result.find('.overlay, .click-text').remove()

                    let thumbnail = result.find('.thumbnail')
                    thumbnail.find('.product .image img').addClass('p-0 ob').css('object-fit', 'cover')
                    thumbnail.replaceWith($('<div class="thumbnail"></div>').html(thumbnail.html()))

                    let title = result.find('.title').detach()
                    result.find('.inner').prepend(title)

                    result.find('.content .button a').addClass('text-uppercase').text('XEM CHI TIẾT')
                    .attr('href', title.text().includes('Swift') ? '#phan-loai-theo-swift' : '#phan-loai-theo-gaming-nitro-predator')
                    
                    result.find('.logo-features').removeClass('d-none')

                    $('.mini-game .section-heading').addClass('mt-1')

                    $('.acerday').removeClass('d-none')

                    let data = {
                        products: product.data('minigame-product'),
                        carousel: title.text().includes('Swift') ? 'swiftProductCarousel' : 'priceProductCarousel'
                    }

                    getProduct(data)
                    // const product_cat = product.data('cat')
                    // const productCatIndex = $(`.product-cats-wrap .swiper-slide[data-cat="${product_cat}"]`).data('index')
                    // const productIndex = product?.data('index')
                    // console.log('index', productCatIndex, productIndex)
                    // $(`.product-cats-wrap .swiper-slide[data-cat="${product_cat}"]`).click()
                    // productSwiper?.slideTo(productCatIndex)
                    // window.productCarousel?.[productCatIndex]?.slideTo(productIndex)

                    // window.location.hash = '#san-pham'
                }
            })

        }

        let initScrollbar = function() {
            if('undefined' == typeof PerfectScrollbar) {
                return
            }

            $('.ps').each(function(index, element){
                let ps = new PerfectScrollbar(element);
            })

            $('.ps').on('touchstart mouseenter', function() {
                fullpage_api.setAllowScrolling(false)
            }).on('touchend mouseleave', function() {
                fullpage_api.setAllowScrolling(true)
            })
        }

        initScrollbar()


        let runPostCarousel = function(){
            let carousel = $('.news-wrap')

            if(carousel.length < 1) {
                return
            }

            runSlider(carousel);
        }

        runPostCarousel()
    });

})(jQuery);